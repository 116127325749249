import {
  CaptionTitle,
  PriceBreakdown,
  ProductTile,
  RBadge,
  RPrice,
} from "@ritual/essentials-for-react";
import { GatsbyImage, IGatsbyImageData, getImage } from "gatsby-plugin-image";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Status } from "../../constants/stockStatus";
import usePromotionDiscount from "../../hooks/usePromotionDiscount";
import useVariation from "../../hooks/useVariation";
import intl from "../../services/intl";
import { navigate } from "../../services/navigation";
import promotionSelectors from "../../store/promotion/selectors";
import metrics from "../../utils/metrics";
import { getPromotionDetails } from "../../utils/promotion";
import ModalCopy from "../../utils/stockStatus";
import { getPropertiesForProductSku } from "../../utils/tracking/helpers";
import Currency from "../Currency";
import ProductButton from "../product/ProductButton";
import { findDefaultPlan } from "../../utils/ritualData";

const ImageWrapper = styled.span`
  .gatsby-image-wrapper {
    transition: transform 0.2s;
  }

  &:hover .gatsby-image-wrapper {
    transform: scale(1.025);
  }

  &.award-badge-image {
    display: block;
    width: 48px;
    height: 48px;

    & img {
      object-fit: scale-down !important;
    }

    @media (min-width: 768px) {
      width: 64px;
      height: 64px;
    }

    &:hover .gatsby-image-wrapper {
      transform: none;
    }
  }
`;

type Flag = {
  content: string;
};

type Image = {
  gatsbyImageData: IGatsbyImageData;
  description: string;
};

type Content = {
  name: {
    name: string;
  };
  summary: string;
  slug: string;
  sku: string;
  shopImages: Image[];
  productSubhead: string;
  stockStatus: string;
  path: string;
  ritualProduct: any;
};

type Item = {
  size: string;
  content: Content;
  tileFlag: Flag;
  titleFlag: Flag;
  awardBadge: Image;
};

type SingleProductTileProps = (props: {
  item: Item;
  location: String;
  currency: {
    type: string;
  };
}) => JSX.Element | null;

const SingleProductTile: SingleProductTileProps = (props) => {
  const {
    item,
    location = "Product List Page Product Cards",
    currency,
  } = props;
  const { size, content, tileFlag, titleFlag, awardBadge } = item;
  const {
    summary,
    shopImages,
    productSubhead,
    ritualProduct,
    stockStatus,
    path,
  } = content;
  const { sku } = ritualProduct;
  const url = `/${path}`;
  const promotion = useSelector(promotionSelectors.bestEligiblePromotion);

  const plan = findDefaultPlan(ritualProduct.ritualPlans, currency);

  const promotionDiscount = usePromotionDiscount(promotion, plan?.plan_id);
  const price = (plan?.amount || 0) / 100;
  const promotionDetails = getPromotionDetails(promotionDiscount, price) as any;
  const promotionDiscountPrice = promotionDetails?.discountPrice;

  const currentPrice = promotionDiscountPrice || price;
  const futurePrice = price;
  const savings = futurePrice - currentPrice;
  const hasSavings = savings > 0;
  const isMonthlyPlan = plan?.billing_type === "recurring" && plan?.interval_count === 30;
  const isSingleSkuPDPUpdates = useVariation("single-sku-pdp-updates", false);

  const productButtonProps = {
    buttonLocation: "PLP",
    prefix: "Add",
    className: "plp-cta fullwidth",
  };

  const outOfStock =
    stockStatus === Status.OutOfStock ||
    item.content.stockStatus === Status.OutOfStock;

  const preOrderStock =
    stockStatus === Status.Preorder ||
    item.content.stockStatus === Status.Preorder;

  const handleCardClick = (e: any) => {
    const target = e?.target;

    if (
      target &&
      target.textContent !== "Add" &&
      target.textContent !== "Join the Waitlist"
    ) {
      metrics.track("Product Clicked", {
        ...getPropertiesForProductSku(sku),
        location: location,
        title: "Learn More",
      });

      navigate(url);
    }
  };

  const handleAddProductTrack = (e: any) => {
    metrics.track("CTA Clicked", {
      location: location,
      title: e?.target?.innerText,
    });
  };

  const hasWaitlist =
    !!ModalCopy?.[stockStatus || item.content.stockStatus]?.[sku];

  return (
    <ProductTile
      name={summary}
      description={productSubhead}
      isBaseTile={false}
      size={size?.toLowerCase()}
      isFeaturedProduct={true}
      url={url}
      handleClick={handleCardClick}
    >
      {shopImages && shopImages[0] && (
        <ImageWrapper slot="product-image">
          <GatsbyImage
            image={
              getImage(shopImages?.[0]?.gatsbyImageData) as IGatsbyImageData
            }
            alt={shopImages?.[0]?.description}
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </ImageWrapper>
      )}
      {(tileFlag || outOfStock || preOrderStock) && (
        <RBadge
          slot="product-image-r-badge"
          badgeColor="white"
          badgeWidth="wide"
        >
          {outOfStock || preOrderStock
            ? intl.t("plp.product-tile.out-of-stock", "Out of Stock")
            : tileFlag?.content}
        </RBadge>
      )}
      {titleFlag && (
        <RBadge
          slot="featured-product-r-badge"
          badgeColor="neutral"
          badgeWidth="wide"
        >
          {titleFlag.content}
        </RBadge>
      )}
      {((hasWaitlist && outOfStock) || !outOfStock) && (
        <div slot="product-image-cta">
          <ProductButton
            product={content}
            trackCTAClick={handleAddProductTrack}
            productButtonProps={productButtonProps}
            location="PLP"
            showPricing={false}
          />
        </div>
      )}
      <PriceBreakdown slot="price-breakdown" spread={true}>
        <RPrice slot="price-breakdown-final" priceClass="final">
          <div>
            {isSingleSkuPDPUpdates && isMonthlyPlan && <span>From </span>}
            {/* @ts-ignore */}
            <Currency value={currentPrice} round={true} />
          </div>
        </RPrice>
        {hasSavings && futurePrice && (
          <RPrice
            slot="price-breakdown-strikethrough"
            priceClass="strikethrough"
          >
            {/* @ts-ignore */}
            <Currency value={futurePrice} round={true} />
          </RPrice>
        )}
        {hasSavings && savings && (
          <CaptionTitle slot="price-breakdown-savings-caption">
            {/* @ts-ignore */}
            <Currency
              value={Math.floor(savings).toFixed(0)}
              round={true}
            />{" "}
            savings
          </CaptionTitle>
        )}
      </PriceBreakdown>
      {awardBadge && (
        <ImageWrapper slot="product-award-image" className="award-badge-image">
          <GatsbyImage
            image={getImage(awardBadge.gatsbyImageData) as IGatsbyImageData}
            alt={awardBadge?.description}
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </ImageWrapper>
      )}
    </ProductTile>
  );
};

export default SingleProductTile;
